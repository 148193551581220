(function () {
  const apiUrl = "https://graphql.datocms.com/";
  const token = "0229647ebd5bc18ae13f90cf483105";

  async function getAllLogos() {
    const query = `
            allLogos {
                category
                brandName
                logotype {
                    url
                    width
                    height
                }
            }
        `;

    return await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: `{ ${query} }`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        return res.data.allLogos;
      })
      .catch((error) => {
        return error;
      });
  }

  async function getAllTestimonials() {
    const query = `
            allTestimonials {
                quote
                company
                name
                role
                photo {
                    url
                    width
                    height
                }
            }
        `;

    return await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: `{ ${query} }`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        return res.data.allTestimonials;
      })
      .catch((error) => {
        return error;
      });
  }

  async function getAllOffers() {
    const query = `
            allOffers {
            title
            textColumn1(markdown: true)
            textColumn2(markdown: true)
            methodologyColumn1(markdown: true)
            methodologyColumn2(markdown: true)
            offerType
            url
            promoCode
            qrCode {
                url
                width
                height
            }
            redeemHint
            id
            headline
            heroImage {
                url
                width
                height
            }
            logo {
                url
                width
                height
            }
            estimationValue
            companySignImage {
                height
                width
                url
            }
            ceoName
            ceoInfo
            category
            certificationText(markdown: true)
            certificationChecklist(markdown: true)
            }
        `;

    return await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: `{ ${query} }`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        return res.data.allOffers;
      })
      .catch((error) => {
        return error;
      });
  }

  async function getSMEOffers() {
    const query = `
            allSmeOffers {
            title
            url
            id
            headline
            heroImage {
                url
                width
                height
            }
            logo {
                url
                width
                height
            }
            estimationValue
            visible
            }
        `;

    return await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: `{ ${query} }`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        return res.data.allSmeOffers;
      })
      .catch((error) => {
        return error;
      });
  }

  const DatoCMS = {
    getAllOffers: getAllOffers,
    getSMEOffers: getSMEOffers,
    getAllLogos: getAllLogos,
    getAllTestimonials: getAllTestimonials,
  };

  window.DatoCMS = DatoCMS;
})();
